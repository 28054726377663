import isEmpty from 'lodash/isEmpty.js'
import { seoApi } from '~/api/seo'
import { ISeo } from '~/types/seo'
import { useAppStore } from '~/store/app'

export default () => {
  const { SEO } = seoApi()
  const { $axios, $pinia, $device } = useNuxtApp()
  const store = useAppStore($pinia)
  const route = useRoute()

  const getSeoData = async () => {
    try {
      const iSeoList: ISeo[] = store.seoData
      if (isEmpty(iSeoList)) {
        const resultAPI = await $axios.get(SEO)
        if (resultAPI.data?.status === 'OK') {
          const result = resultAPI.data.data
          result.forEach((ISeo: ISeo) => {
            const { children, ...parentWithoutChildren } = ISeo
            iSeoList.push(parentWithoutChildren)
            if (ISeo.children) {
              ISeo.children.forEach((child: ISeo) => {
                iSeoList.push(child)
              })
            }
          })
          store.setSeoData(iSeoList)
        }
      }
      const seoData = iSeoList.find(
        (ISeo) => `/${ISeo.alias}` === route.path || (ISeo.alias === '/' && route.path === '/')
      )
      return seoData
    } catch (error) {
      console.error(error)
    }
  }
  onMounted(async () => {
    const bodyElement = document.body
    const deviceClass = $device.isMobileOrTablet ? 'mobile' : 'desktop'
    bodyElement.classList.add(deviceClass, 'swal2-toast-shown')

    const seoData = await getSeoData()
    useBaseSeo({
      content_title: seoData?.content_title || '',
      meta_title: seoData?.meta_title || '',
      meta_description: seoData?.meta_description || '',
      meta_keyword: seoData?.meta_keyword || '',
      alias: seoData?.alias || '',
      seo_img: seoData?.seo_img || ''
    })
  })
}
